import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Link } from "react-scroll";

import { FiX, FiMenu } from "react-icons/fi";

// import logoDefault from "../../assets/images/logo/logo.png";
// import logoLight from "../../assets/images/logo/logo-light.png";
// import logoDark from "../../assets/images/logo/logo-dark.png";
// import logoSymbolDark from "../../assets/images/logo/logo-symbol-dark.png";
// import logoSymbolLight from "../../assets/images/logo/logo-symbol-light.png";
import logo1 from "../../assets/images/digital-pulse-marketing-barcelona.svg";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../Language/LanguageSelector";
class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const header = document.querySelector(".header-area");
    if (window.scrollY > 0) {
      header.classList.add("scrolled");
    } else {
      header.classList.remove("scrolled");
    }
  };
  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");


    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    const { logo, color = "default-color" } = this.props;
    let logoUrl;
    if (logo === "light") {
      logoUrl = <img src={logoLight} alt="Digital Agency" />;
    } else if (logo === "dark") {
      logoUrl = <img src={logo1} alt="Digital Agency" />;
    } else if (logo === "symbol-dark") {
      logoUrl = <img src={logo1} alt="Digital Agency" />;
    } else if (logo === "symbol-light") {
      logoUrl = <img src={logoSymbolLight} alt="Digital Agency" />;
    } else {
      logoUrl = (
        <img src={logo1} width="100px" height="100px" alt="Digital Agency" />
      );
    }

    return (
      <header
        className={`header-area formobile-menu header--transparent ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <Link
                    activeClass="active"
                    to="home" // El id de la sección a la que quieres desplazarte
                    spy={true}
                    className="menu-item"
                    smooth={true}
                    offset={-70}
                    duration={1000}
                  >
            {this.props.t("header.home")}

                  </Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to="about" // El id de la sección a la que quieres desplazarte
                    spy={true}
                    className="menu-item"
                    smooth={true}
                    offset={-70}
                    duration={1000}
                  >
            {this.props.t("header.about")}
                  </Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to="servicios" // El id de la sección a la que quieres desplazarte
                    spy={true}
                    smooth={true}
                    offset={-70}
                    className="menu-item"
                    duration={1000}
                  >
                                {this.props.t("header.services")}

                  </Link>
                </li>

                <li>
                  <Link
                    activeClass="active"
                    to="pages" // El id de la sección a la que quieres desplazarte
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={1000}
                    className="menu-item"
                  >
                                {this.props.t("header.portfolio")}

                  </Link>
                 
                </li>

          
              </ul>
            </nav>
            <div className="header-btn">
              <Link
                activeClass="active"
                to="contact" // El id de la sección a la que quieres desplazarte
                spy={true}
                smooth={true}
                offset={-70}
                className=" menu-item rn-btn"
                duration={1000}
              >
                            {this.props.t("header.contact")}

              </Link>
            </div>
            <div className="header-btn">
              <LanguageSelector />
              </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
const Header = (props) => {
  const { t } = useTranslation();

  return <HeaderComponent {...props} t={t} />;
};

export default Header;

